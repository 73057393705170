'use strict';

angular.module('windmanagerApp')
  .directive('siteMap', function($timeout, NgMap) {
    return {
      templateUrl: 'components/directives/site/siteMap/siteMap.html',
      restrict: 'EA',
      controller: 'SiteMapCtrl',
      controllerAs: 'siteMap',
      scope: {
        popups: '='
      },
      link: function(scope, element, attrs) {
        NgMap.getMap().then(function(map) {
          $timeout(()=> {
            google.maps.event.trigger(map, 'resize');
          });
        })
      }
    };
  });
